<template>
  <v-card>
    <v-card-title>
      <v-col>
        <span class="headline">{{ title + " - " }}</span>
        <span class="headline">{{ nameCard }}</span>
      </v-col>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" sm="4" md="4">
            <v-autocomplete
              dense
              v-model="venta.linea"
              :items="lineas"
              item-text="nombre"
              item-value="nombre"
              :append-icon="lineas.length == 0 ? 'mdi-reload' : ''"
              clearable
              hide-details
              label="Lista de lineas"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>Esta línea no existe</v-list-item-title>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-autocomplete
              dense
              v-model="venta.agenteVenta"
              item-text="nombre"
              item-value="nombre"
              :items="agentes"
              :append-icon="agentes.length == 0 ? 'mdi-reload' : ''"
              clearable
              hide-details
              label="Agentes de venta"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>Este agente no existe</v-list-item-title>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-autocomplete
              dense
              v-model="venta.periodo"
              :items="periodos"
              item-text="idP"
              item-value="idP"
              :append-icon="periodos.length == 0 ? 'mdi-reload' : ''"
              @input="getMat"
              clearable
              hide-details
              label="Lista de periodos"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>Este periodo no existe</v-list-item-title>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-autocomplete
              dense
              auto-select-first
              v-model="venta.materias"
              :items="materiasByp"
              item-text="nombre"
              item-value="nombre"
              :search-input.sync="search"
              @change="search = null"
              clearable
              multiple
              label="Lista de materias"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>Este curso no existe</v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title
                    title
                    v-text="item.nombre"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    subtitle
                    v-text="item.codigo"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>

              <template v-slot:selected="{ item }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.nombre"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.codigo"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-autocomplete
              dense
              v-model="venta.medioVenta"
              :items="medioVenta"
              item-text="nombre"
              item-value="nombre"
              chips:append-icon="medioVenta.length == 0 ? 'mdi-reload' : ''"
              clearable
              hide-details
              label="Medio de la venta"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title
                    >Este medio de venta no existe</v-list-item-title
                  >
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              dense
              v-model="venta.notas"
              label="Notas"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              dense
              v-model="venta.linkDrive"
              label="LINK DRIVE"
            ></v-text-field>
          </v-col>
          <!-- <v-col cols="12" sm="12" md="12">
            <span class="headline" color="black">Pagos</span>
          </v-col> -->
          <v-col cols="12" sm="3" md="3">
            <v-text-field
              dense
              v-model="venta.cuotasTotales"
              @change="refrescar"
              label="Abonos totales"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-text-field
              dense
              v-model="venta.valorAcordado"
              prefix="$"
              label="Valor acordado"
            ></v-text-field>
            {{ venta.valorAcordado | currency }}
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-date-picker
                  v-model="dates"
                  locale="ES"
                  no-title
                  multiple
                ></v-date-picker>
              </v-col>
              <v-col cols="12" sm="4" md="6">
                <v-container>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="dates"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        dense
                        v-model="dates"
                        multiple
                        chips
                        small-chips
                        label="Fechas de pagos"
                        readonly
                        deletable-chips
                        clearable
                        v-bind="attrs"
                        v-on="on"
                      ></v-combobox>
                    </template>
                  </v-menu>
                </v-container>
                <v-container>
                  <v-select
                    dense
                    v-model="frec"
                    :items="frecuencia"
                    item-text="name"
                    item-value="dias"
                    label="Se repite"
                    @change="fechas(frec)"
                    persistent-hint
                    return-object
                    single-line
                    clearable
                  ></v-select>
                </v-container>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="accent" text @click="close">Cerrar</v-btn>
      <v-btn :disabled="guardando" color="primary" text @click="saves()">
        Guardar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { host } from "../Tools/variables";
import firebase from "firebase";
import router from "../Tools/rutas";
import axios from "axios";

import data from "../Tools/data";

export default {
  name: "ventaView",

  props: [
    "nameCard",
    "info",
    "idCli",
    "title",
    "fechasV",
    "frecV",
    "linkDrive",
  ],

  data() {
    return {
      guardando: false,
      normal: true,
      search: null,
      venta: {
        ventaId: null,
        linea: null,
        medioVenta: null,
        agenteVenta: null,
        periodo: null,
        linkDrive: null,
        materias: [],
        valorAcordado: null,
        notas: null,
        cuotasTotales: null,
      },
      menu: false,
      menuT: false,
      dates: [],
      frecuencia: [
        { name: "Mensual", dias: 30 },
        { name: "Quincenal", dias: 15 },
        { name: "Semanal", dias: 7 },
      ],
      frec: null,
      materiasByp: [],
      lineas: [],
      agentes: [],
      periodos: [],
      medioVenta: [],
    };
  },

  async created() {
    // LINEAS
    if (this.$store.state.lineas.length == 0) {
      this.lineas = await data.dataList("/lineas", "lista", "listar", "lineas");
    } else {
      this.lineas = this.$store.state.lineas;
    }
    // AGENTES
    if (this.$store.state.agentes.length == 0) {
      this.agentes = await data.dataList(
        "/agentes",
        "lista",
        "listar",
        "agentes"
      );
    } else {
      this.agentes = this.$store.state.agentes;
    }
    // PERIODOS
    if (this.$store.state.periodos.length == 0) {
      this.periodos = await data.dataList(
        "/periodos",
        "lista",
        "listar",
        "periodos"
      );
    } else {
      this.periodos = this.$store.state.periodos;
    }
    // MEDIO VENTA
    if (this.$store.state.mediosVenta.length == 0) {
      this.medioVenta = await data.dataList(
        "/mediosVenta",
        "lista",
        "listar",
        "mediosVenta"
      );
    } else {
      this.medioVenta = this.$store.state.mediosVenta;
    }
  },

  async mounted() {
    await data.dataList("/cursos", "lista", "listar", "materias");
    if (this.info != null) {
      this.dates = this.fechasV;
      this.frec = this.frecV;
      this.venta.linea = this.info.linea;
      this.venta.medioVenta = this.info.medioVenta;
      this.venta.periodo = this.info.periodo;
      this.venta.agenteVenta = this.info.agenteVenta;
      this.venta.materias = this.info.materias;
      this.venta.valorAcordado = this.info.valorAcordado;
      this.venta.linkDrive = this.info.linkDrive;
      this.venta.notas = this.info.notas;
      this.venta.cuotasTotales = this.info.cuotasTotales;
      this.venta.ventaId = this.info.ventaId;
      this.getMat();
    } else {
      if (this.linkDrive != null) {
        this.venta.linkDrive = this.linkDrive;
      }
    }
  },
  methods: {
    getMat() {
      this.materiasByp = [];
      for (var c = 0; c < this.$store.state.materias.length; c++) {
        if (this.$store.state.materias[c]["periodo"] == this.venta.periodo) {
          this.materiasByp.push({
            nombre: this.$store.state.materias[c]["cursoBase"],
            codigo: this.$store.state.materias[c]["codigoMadre"],
          });
        }
      }
    },

    saves() {
      if (this.info == null) this.saveVenta();
      else {
        this.saveEditV();
      }
    },

    saveVenta() {
      this.guardando = true;
      this.saveVenta2(this.guardando);
    },

    saveVenta2(g) {
      if (g == true) {
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then((idToken) => {
            const fd = new FormData();
            fd.append("token", idToken);
            fd.append("tipo", "agregar");
            fd.append("estudianteId", this.idCli);
            fd.append("cursos", this.venta.materias);
            fd.append("periodo", this.venta.periodo);
            fd.append("linea", this.venta.linea);
            fd.append("medioVenta", this.venta.medioVenta);
            fd.append("agenteVentas", this.venta.agenteVenta);
            fd.append("cantidadMaterias", this.venta.materias.length);
            fd.append("valorAcordado", this.venta.valorAcordado);
            fd.append("cuotasTotales", this.venta.cuotasTotales);
            fd.append("fechasAcuerdoPago", this.dates);
            fd.append(
              "notas",
              this.venta.notas == null ? "" : this.venta.notas
            );
            axios.post(host + "/ventas", fd).then((result) => {
              let data = result.data.text;
              if (data == "OK") {
                router.push("../clientesRoot");
              } else {
                this.$alert(data);
              }
              this.guardando = false;
            });
          })
          .catch(function (error) {
            this.$alert("Error " + error.message);
          });
      }
    },

    fechas(info) {
      if (info.dias == 30) {
        for (var m = 0; m < this.venta.cuotasTotales - 1; m++) {
          this.dates.push(
            this.$moment(this.dates[m]).add(1, "M").format("YYYY-MM-DD")
          );
        }
      }
      if (info.dias == 15) {
        for (var q = 0; q < this.venta.cuotasTotales - 1; q++) {
          this.dates.push(
            this.$moment(this.dates[q]).add(15, "days").format("YYYY-MM-DD")
          );
        }
      }
      if (info.dias == 7) {
        for (var s = 0; s < this.venta.cuotasTotales - 1; s++) {
          this.dates.push(
            this.$moment(this.dates[s]).add(7, "days").format("YYYY-MM-DD")
          );
        }
      }
    },

    close() {
      this.$emit("toggle", "close");
    },

    saveEditV() {
      this.guardando = true;
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("ventaId", this.venta.ventaId);
          fd.append("tipo", "editar");
          fd.append("estudianteId", this.idCli);
          fd.append("cursos", this.venta.materias);
          fd.append("periodo", this.venta.periodo);
          fd.append("linea", this.venta.linea);
          fd.append("medioVenta", this.venta.medioVenta);
          fd.append("agenteVentas", this.venta.agenteVenta);
          fd.append("linkDrive", this.venta.linkDrive);
          fd.append("cantidadMaterias", this.venta.materias.length);
          fd.append("valorAcordado", this.venta.valorAcordado);
          fd.append("cuotasTotales", this.venta.cuotasTotales);
          fd.append("fechasAcuerdoPago", this.dates);
          fd.append("notas", this.venta.notas == null ? "" : this.venta.notas);
          axios.post(host + "/ventas", fd).then((result) => {
            let data = result.data.text;
            if (data == "OK") {
              this.$emit("toggle", "refresh");
            } else {
              this.$alert("Error: " + data);
            }
            this.guardando = false;
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    refrescar() {
      if (this.dates.length != 0) {
        this.frec = null;
        var a = this.dates[0];
        this.dates = [];
        this.dates.push(a);
      }
    },
  },
};
</script>

<style>
</style>